import React from "react";
import { FormattedMessage } from "react-intl";

export default function ContactInformation() {
  return (
    <>
      <FormattedMessage id="common.company_street" />
      <br />
      <FormattedMessage id="common.company_district" />
      <br />
      <FormattedMessage id="common.telephone_label_short" />:{" "}
      <FormattedMessage id="common.company_tel_value" />
      <br />
      <FormattedMessage id="common.email_label_short" />:{" "}
      <FormattedMessage id="common.company_email_value" />
    </>
  );
}
