import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function PageSection(props) {
  const { backgroundColor = "white", title, children: content } = props;

  return (
    <div
      className="py-5"
      style={{
        backgroundColor,
      }}
    >
      <Container>
        <Row className="pb-3">
          <Col>
            <h1>{title}</h1>
          </Col>
        </Row>
        {content}
      </Container>
    </div>
  );
}
