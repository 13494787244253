import * as React from "react";

export default function Header(props) {
  const { image } = props;

  return (
    <>
      <div
        style={{
          height: "500px",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
        }}
        className="d-none d-sm-block"
      />
    </>
  );
}
