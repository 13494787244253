import * as React from "react";
import Layout from "../components/Layout";
import ImageContainer from "../components/ImageContainer";
import pen from "../images/contact.jpg";
import PageSection from "../components/PageSection/PageSection";
import { Helmet } from "react-helmet";
import useFormattedMsg from "../hooks/useFormattedMsg";
import { Row, Col } from "react-bootstrap";
import ContactInformation from "../components/ContactInformation/ContactInformation";
import { FormattedMessage } from "gatsby-plugin-react-intl";

const ContactPage = () => {
  const title = useFormattedMsg("contact.title");
  const subtitle = useFormattedMsg("contact.subtitle");
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Layout>
        <ImageContainer image={pen} />
        <PageSection title={subtitle}>
          <Row>
            <Col xs={12} md={9} className="order-md-last pb-4">
              <FormattedMessage id="contact.main_text" />
            </Col>
            <Col xs={12} md={3} className="order-md-first">
              <div className="mb-3">
                <ContactInformation />
              </div>
              <div>
                <h4>
                  <FormattedMessage id="contact.office_hours_label" />
                </h4>
                <FormattedMessage id="contact.office_hours_value" />
              </div>
            </Col>
          </Row>
        </PageSection>
      </Layout>
    </>
  );
};

export default ContactPage;
